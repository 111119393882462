import axios from '@/axios.js'

export default {    
  async getOrder({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/order', {
        params: params
      })
      commit('SET_ORDER', response.data.data)
      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async getPCode({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/pcode', {
        params: params
      })
      commit('SET_PCODE', response.data.data)
      return response.data
    } catch (e) {
      console.log(e)
    }
  },

  async getInventory({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/inventory', {
        params: params
      })
      if(!params.forDownload){
      commit('SET_INVENTORY', response.data.data)
      }
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async getPromoCode({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/promo-code', {
        params: params
      })

      if(!params.forDownload){
        
      commit('SET_REFERENCE', response.data.data)
      }
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async getDo({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/do', {
        params: params
      })
      if(!params.forDownload){
      commit('SET_DO', response.data.data)
      }
      return response.data
    } catch (e) {
      console.log(e)
    }
  },
  async getWarranty({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/warranty', {
        params: params
      })
      if(!params.forDownload){
      commit('SET_WARRANTY', response.data.data)
      }
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error("Error get report warranty");
    }
  },
  async getSkipScan({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/skip-scan', {
        params: params
      })
      if(!params.forDownload){
      commit('SET_SKIP_SCAN', response.data.data)
      }
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error("Error get report warranty");
    }
  },

  async getReturn({ commit }, params) {
    try {
      const response = await axios.get('sales-and-purchase/report/return', {
        params: params
      })
      if(!params.forDownload){
      commit('SET_RETURN', response.data.data)
      }
      return response.data
    } catch (e) {
      console.error(e)
      throw new Error("Error get report return");
    }
  },
}
