export default {
  order: state => state.order,
  pcode : state => state.pcode,
  inventory : state => state.inventory,
  promo : state => state.promo,
  deliveryOrder : state => state.deliveryOrder,
  warranty : state => state.warranty,
  skipScan: state => state.skipScan,
  return: state => state.return
}
