export default {
  order: [],
  pcode : [],
  inventory : [],
  promo : [],
  deliveryOrder : [],
  warranty : [],
  skipScan:[],
  return:[]
}
